import {useContext, useState} from 'react';
import BackendWrapper from './BackendWrapper';
import UserContext from './UserContext'

function Comment(props) {
    return (
    <div className="comment">
        <b>{props.value.username}: </b>
        <span>{props.value.content}</span>
    </div>)
}

function Post(props){
    const [comment, setComment] = useState("")
    const context = useContext(UserContext)

    function handleComment(e) {
        e.preventDefault();
        if(comment === "") return;
        BackendWrapper.makeComment(props.value, {content: comment, username: context.username})
        setComment("");
    }

    return (
    <div className="post">
        <h3 className="title">{props.value.title}</h3>
        <span className="user">{props.value.username}</span>
        <p className="content">{props.value.content}</p>
        <div className="comments">
            {props.value.comments.map((comment, index) => (
                <Comment key={index} value={comment} />
            ))}
        </div>
        <div className="commentWriter">
            <input type="text" placeholder="Write a comment..." value={comment} onChange={(e) => {setComment(e.target.value)}}/>
            <button onClick={handleComment}>Post</button>
        </div>
    </div>);
}

export default Post;
import React, {useState, useContext} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import BackendWrapper from './BackendWrapper';

import PostContext from './PostContext';
import UserContext from './UserContext';

function NewPost() {
    const navigate = useNavigate();
    const context = useContext(PostContext);
    const userContext = useContext(UserContext);

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    function cancel(e) {
        e.preventDefault();
        navigate(-1)
    }

    function submit(e) {
        e.preventDefault();
        BackendWrapper.makePost({title, content}).then(() => {
            navigate(-1);
            context.getPosts();
        }).catch(e => alert(e))
    }

    return (
        <form className="newPost">
            <input id="title" type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Post title..." />
            <span>- {userContext.username}</span>
            <textarea id="content" name="content" rows={3} value={content} onChange={(e) => setContent(e.target.value)} placeholder="Your post goes here..." />
            <div className="right">
            <button onClick={cancel}>Cancel</button>
            <button onClick={submit}>Submit</button>
            </div>
        </form>
    );
}

export const PostButton = () => {
    return (
        <NavLink to="new" id="newPostButton">
                Write new post
        </NavLink>
    )
}

export default NewPost;

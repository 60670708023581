import './App.css';
import {useState, useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import BackendWrapper from './BackendWrapper';

import Header from './Header.js';
import UserContext from './UserContext';

function App() {
  let [username, setUsername] = useState("");

  async function refreshUsername() {
    setUsername(await BackendWrapper.getUsername());
  }

  useEffect(() => {
    refreshUsername();
  }, [])

  return (
    <UserContext.Provider value={{username, refreshUsername}}>
      <div className="App">
        <Header />
        
        <Outlet />
      </div>
    </UserContext.Provider>
  );
}

export default App;

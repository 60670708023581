import React, {useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import BackendWrapper from './BackendWrapper';
import UserContext from './UserContext';

function Login() {
    let navigate = useNavigate();
    const context = useContext(UserContext);
    let [username, setUsername] = useState(context.username || "");

    async function handleSubmit(e) {
        e.preventDefault();
        try{
            await BackendWrapper.setUsername(username);
            context.refreshUsername();
            navigate(-1);
        } catch(error) {
            alert(error.response.data);
        }
    }

    return(
    <div id="login">
        <span>Enter a username:</span>
        <input type="text" value={username} onChange={(e) => {setUsername(e.target.value)}}></input>
        <div className="right">
            <button onClick={handleSubmit}>Submit</button>
        </div>
    </div>);
}

export default Login;
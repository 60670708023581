import { useEffect, useState} from 'react';
import { Outlet } from 'react-router-dom';
import Post from './Post.js';
import BackendWrapper from './BackendWrapper.js';

import PostContext from './PostContext.js';

function Feed() {
    const [posts, setPosts] = useState([]);

    async function getPosts() {
        try {
            let initPosts = await BackendWrapper.getPosts();
            setPosts(initPosts);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        getPosts();
        let t = setInterval(getPosts, 5*1000);
        return () => {clearInterval(t)}
    }, []);

    return (
        <div className="feed">
            <PostContext.Provider value={{getPosts}}>
                <Outlet />
            </PostContext.Provider>
            {
                posts?posts.map(post => (
                    <Post key={post.id} value={post}/>
                )):"Loading posts..."
            }
        </div>
    )
}

export default Feed;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import BackendWrapper from './BackendWrapper';

import Feed from './Feed.js';
import Login from './Login';
import NewPost, {PostButton} from './NewPost.js'

BackendWrapper.setBaseUrl('api.jbgall.workers.dev');

ReactDOM.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<Feed />}>
            <Route path="new" element={<NewPost />} />
            <Route path="" element={<PostButton />} />
          </Route>
          <Route path="login" element={<Login></Login>} />
        </Route>
      </Routes>
    </BrowserRouter>,
  document.getElementById('root')
);

import axios from 'axios';

let baseUrl;

let BackendWrapper = {
    setBaseUrl(url) {
        baseUrl = url;
        axios.defaults.withCredentials = true
    },
    getUrl() {
        return baseUrl;
    },
    getPosts() {
        return new Promise((resolve, reject) => {
            axios.get(`https://${baseUrl}/posts`).then(result => resolve(result.data))
            .catch(reject);
        });
    },
    makePost(post) {
        return new Promise((resolve, reject) => {
            axios.post(`https://${baseUrl}/posts`, post).then(result => resolve(result.data))
            .catch(reject);
        });
    },
    makeComment(post, comment) {
        return new Promise((resolve, reject) => {
            return axios.post(`https://${baseUrl}/posts/${post.id}/comment`, comment);
        });
    },
    getUsername() {
        return new Promise((resolve, reject) => {
            axios.get(`https://${baseUrl}/auth`).then(result => resolve(result.data))
            .catch(reject)
        });
    },
    setUsername(username) {
        return new Promise((resolve, reject) => {
            axios.post(`https://${baseUrl}/auth`, {username}).then(() => resolve(username))
            .catch(reject);
        });
    }
}

export default BackendWrapper;
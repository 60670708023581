import {useContext} from 'react';
import {Link} from 'react-router-dom';
import UserContext from './UserContext';

export default function Header(props) {
    const context = useContext(UserContext);

    return (
        <div className="header">
            <ul>
                <Link to="/"><li>Home</li></Link>
                <Link to="/login"><li>{context.username || "Login"}</li></Link>
            </ul>
        </div>
    )
}